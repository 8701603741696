import client from 'libs/HttpClient';

class AdminAddonsService {
  static getList({ params }) {
    return client.get(`addons/list`, { params });
  }
  static add(request) {
    return client.post(`addons/add`, request);
  }
  static get(params) {
    return client.get(`addons/view`, { params });
  }
  static edit(request) {
    return client.put(`addons/edit`, request);
  }
  static delete(params) {
    return client.delete(`addons/delete`, { params });
  }
  static changeStatus(request) {
    return client.put(`addons/status-change`, request);
  }
  static getCategories(params) {
    return client.get(`addons/get-categories`, { params });
  }
}

export { AdminAddonsService };
