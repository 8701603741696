import { AdminAddonsService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetAddons = (params, onSuccess, onError) => {
  return useQuery(
    ['addons_list', [params]],
    () => {
      return AdminAddonsService.getList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useAddAddon = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAddonsService.add, {
    onSuccess,
    onError,
  });
};
const useDeleteAddon = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAddonsService.delete, {
    onSuccess,
    onError,
  });
};
const useChangeAddonStatus = (onSuccess, onError) => {
  return useMutation(AdminAddonsService.changeStatus, {
    onSuccess,
    onError,
  });
};
const useEditAddon = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAddonsService.edit, {
    onSuccess,
    onError,
  });
};
const useGetAddon = (params, onSuccess, onError) => {
  return useQuery(
    ['addon_detail', [params]],
    () => {
      return AdminAddonsService.get({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetCategoriesAddon = (params, onSuccess, onError) => {
  return useQuery(
    ['get-categories', [params]],
    () => {
      return AdminAddonsService.getCategories(params);
    },
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useGetAddons,
  useAddAddon,
  useDeleteAddon,
  useChangeAddonStatus,
  useEditAddon,
  useGetAddon,
  useGetCategoriesAddon,
};
