import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST, ADD, EDIT, VIEW } from 'config';

const AddonsList = React.lazy(() => import('./ListAddon'));
const AddAddons = React.lazy(() => import('./AddAddon'));
const ViewAddons = React.lazy(() => import('./ViewAddons'));
const EditAddons = React.lazy(() => import('./EditAddons'));

const AddonsRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path={LIST} element={<AddonsList t={t} />} />
      <Route exact path={ADD} element={<AddAddons t={t} />} />
      <Route exact path={VIEW + ':addon_id'} element={<ViewAddons t={t} />} />
      <Route exact path={EDIT + ':addon_id'} element={<EditAddons t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AddonsRoute.propTypes = {
  t: PropTypes.func,
};
export default AddonsRoute;
